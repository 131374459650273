import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function InfoAccessModal({ userType, userPremium }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dateToString = (date) => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return day + " " + months[month] + " " + year;
  };

  return (
    <>
      <Link className="dropdown-item" onClick={handleShow}>
        {userType ? (
          <>
            <i className="bi bi-unlock-fill me-1"></i>Premium Access
          </>
        ) : (
          <>
            <i className="bi bi-lock-fill me-1"></i>Free Access
          </>
        )}
      </Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {userType ? "Premium Access" : "Free Access"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userType ? (
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                __html: `Your current account is Premium Access until <b>${dateToString(
                  userPremium
                )}</b>. Thank you for support!`,
              }}
            ></p>
          ) : (
            <>
              <p
                className="m-0 d-inline"
                dangerouslySetInnerHTML={{
                  __html:
                    "Your current account is Free Access. You can only shorten a link <b>3 times per month</b> and <b>can't change the short link</b> that has been created. ",
                }}
              ></p>
              <Link
                to={
                  "https://wa.me/6283120565298?text=Hello%2C%20I%20want%20to%20upgrade%20my%20LinkIn%20account"
                }
                target="_blank"
              >
                Upgrade to get Premium Access
              </Link>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Understand
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InfoAccessModal;
