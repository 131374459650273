import React from "react";
import LogoutModal from "./LogoutModal";
import { Link, useLocation } from "react-router-dom";
import InfoAccessModal from "./InfoAccessModal";

const Navbar = ({ logedIn, loading, name, userType, userPremium }) => {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container">
        <Link to="/" className="navbar-brand ms-md-0" href="#">
          <i className="bi bi-link-45deg text-primary me-1"></i>Link
          <span className="text-danger">In</span>
        </Link>
        {loading ? (
          <div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : location.pathname === "/" ? (
          logedIn ? (
            <Link
              to={"/dashboard"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Dashboard
            </Link>
          ) : (
            <Link
              to={"/login"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Login
            </Link>
          )
        ) : (
          <div className="dropdown">
            <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-person-fill"></i>
              <div className="d-none d-md-inline ms-1">{name}</div>
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li className="dropdown-item d-block d-md-none">
                <i className="bi bi-person-fill me-1"></i>
                {name}
              </li>
              <li>
                <InfoAccessModal
                  userType={userType}
                  userPremium={userPremium}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <LogoutModal />
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
