import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import NavAuth from "../components/NavAuth";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [passMatch, setPassMatch] = useState(false);

  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    setCheckPassword(regex.test(password));
    // eslint-disable-next-line
  }, [password]);

  useEffect(() => {
    if (confPassword && confPassword === password) {
      setPassMatch(true);
    } else {
      setPassMatch(false);
    }
    // eslint-disable-next-line
  }, [password, confPassword]);

  const refreshToken = async () => {
    try {
      setLoadingRegister(true);
      await axios.get(`${apiUrl}/token`);
      navigate("/dashboard");
    } catch (error) {
      setLoadingRegister(false);
    }
  };

  const register = async (e) => {
    e.preventDefault();

    try {
      setLoadingRegister(true);
      const response = await axios.post(`${apiUrl}/register`, {
        name,
        email,
        password,
        confPassword,
      });

      toast.success(response.data.msg, {
        position: "bottom-right",
      });

      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      setLoadingRegister(false);
      if (error.response.status === 400) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
    }
  };

  const continueWithGoogle = useGoogleLogin({
    onSuccess: async (responseGoogle) => {
      try {
        setLoadingGoogle(true);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${responseGoogle.access_token}`,
            },
            withCredentials: false,
          }
        );
        const name = res.data.name;
        const email = res.data.email;
        const response = await axios.post(`${apiUrl}/google`, {
          name,
          email,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.msg, {
            position: "bottom-right",
          });
        }
        setLoadingGoogle(false);
      }
    },
  });

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center min-vh-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5 p-3">
          <NavAuth />
          <div className="card card-body">
            <form onSubmit={register}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Your name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Someone"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="email@domain.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className={
                    "form-control " +
                    (!password ? "" : checkPassword ? "is-valid" : "is-invalid")
                  }
                  id="password"
                  placeholder="********"
                  minLength={8}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  8 characters minimum, consisting of 1 uppercase and lowercase
                  letter, 1 special character.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="confPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className={
                    "form-control " +
                    (!confPassword ? "" : passMatch ? "is-valid" : "is-invalid")
                  }
                  id="confPassword"
                  placeholder="********"
                  minLength={8}
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                  required
                  disabled={!checkPassword}
                />
                <div className="invalid-feedback">Must match the password.</div>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-w"
                  type="submit"
                  disabled={
                    !name ||
                    !email ||
                    !checkPassword ||
                    !passMatch ||
                    loadingRegister
                  }
                >
                  {loadingRegister ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
            <div className="divider d-flex align-items-center my-3">
              <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-outline-danger btn-w"
                onClick={() => continueWithGoogle()}
                disabled={loadingRegister || loadingGoogle}
              >
                {loadingGoogle ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Continue with Google"
                )}
              </button>
            </div>
          </div>
          <div className="text-center mt-3">
            <Link to={"/"}>Back to Home</Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
