import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EditModal({ userType, link, msg }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = window.location.href
    .split("http://")
    .join("")
    .split("https://")
    .join("")
    .split("dashboard")
    .join("");
  const navigate = useNavigate();

  const regex =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:\d{1,5})?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/;
  const [show, setShow] = useState(false);
  const [short, setShort] = useState(link.short);
  const [full, setFull] = useState(link.full);
  const [exist, setExist] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setExist(false);
    setLoading(false);
  };
  const handleShow = () => {
    setShort(link.short);
    setFull(link.full);
    setShow(true);
  };

  useEffect(() => {
    if (short !== link.short) {
      const delayDebounceFn = setTimeout(() => {
        check();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setExist(false);
    }
    // eslint-disable-next-line
  }, [short]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const check = async () => {
    try {
      await axiosJWT.post(`${apiUrl}/exist`, {
        short,
      });
      setExist(false);
    } catch (error) {
      if (error.response.status === 400) {
        setExist(true);
      }
    }
  };

  const updateLink = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosJWT.patch(`${apiUrl}/link/${link._id}`, {
        short,
        full,
      });
      handleClose();
      setLoading(false);
      msg(response.data.msg);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-primary me-1" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <form onSubmit={updateLink}>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="short" className="form-label">
                Short Link
                {!userType ? (
                  <i
                    className="bi bi-lock-fill ms-1"
                    title="Premium Access needed"
                  ></i>
                ) : (
                  ""
                )}
              </label>
              <div className="input-group">
                <span className="input-group-text">{url}</span>
                <input
                  type="text"
                  className={
                    "form-control " +
                    (!short || !userType
                      ? ""
                      : exist
                      ? "is-invalid"
                      : "is-valid")
                  }
                  id="short"
                  value={short}
                  onChange={(e) => setShort(e.target.value)}
                  disabled={!userType}
                  required
                />
              </div>
              <div id="shortHelp" className="form-text">
                3 characters minimum.
              </div>
            </div>
            <div>
              <label htmlFor="full" className="form-label">
                Full Link
              </label>
              <input
                type="text"
                className={
                  "form-control " +
                  (!full ? "" : regex.test(full) ? "" : "is-invalid")
                }
                id="full"
                value={full}
                onChange={(e) => setFull(e.target.value)}
                required
              />
              <div className="invalid-feedback">Must contain a valid link.</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                !short || !full || exist || !regex.test(full) || loading
              }
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Update"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default EditModal;
